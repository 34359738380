
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import backgroundImage from '../background.jpg';
import {ThemeProvider} from '@material-ui/core/styles';
import GTheme from './Theme';

import Button from '@material-ui/core/Button';


const useStyles = makeStyles({
    
    
    center:{
      textAlign: "center",
    },

    verticalCenter:{
    top: "30vh",
    height: "30vh"
    },

    background: {
      position:"static",
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',
      alignText: 'center',
      alignContent: 'center',
      height: "100vh",
      backgroundSize: 'contain',
      flexGrow: '1 1 auto'    
},
  });


var GoToHome = (props) => {
    const classes = useStyles();
    console.log(props.name)


return ( 
  <ThemeProvider theme={GTheme}>
  <div className={classes.background}>
    <div className={classes.verticalCenter}/>
    <div className={classes.center}>
            <Button
                variant="contained"
                edge="start"
                size="medium" 
                id="goHome"
              >
                  Go to Home Screen
            </Button>
    </div>
  </div>
  </ThemeProvider>
    )

}

export default GoToHome