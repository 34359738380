import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './i18n';
import Amplify from 'aws-amplify';
import awsconfig from './components/aws-exports-copy'; 
import GoToHome from './components/GoToHome';

//import ChooseJourneyFree from './components/ChooseJourneyFree';

const Home = lazy(() => import('./components/GillyHome2'));
const SignIn = lazy(() => import ("./components/SignIn"));
const SignUp = lazy(() => import ("./components/SignUp"));
const ForgotPassword= lazy(() => import ("./components/ForgotPassword"));
const PrivateRoute = lazy(() => import("./components/PrivateRoute"));
const ConfirmEmail = lazy(() => import ("./components/ConfirmEmail"));
const ResetPassword = lazy(() => import ("./components/ResetPassword"));
const WelcomeScreen = lazy(() => import ("./components/Welcome"));
const SignUpLIL = lazy(() => import ("./components/SignUpLIL"));
const Errorpage = lazy(() => import ("./components/Errorpage"));
const AutoConfirmEmail = lazy(() => import ("./components/AutoConfirmEmail"));


awsconfig.aws_cognito_identity_pool_id       = process.env.REACT_APP_COGNITO_POOL_ID
awsconfig.aws_user_pools_id                  = process.env.REACT_APP_USER_POOLS_ID;
awsconfig.aws_user_pools_web_client_id       = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID;
awsconfig.aws_cloud_logic_custom[0].endpoint = process.env.REACT_APP_ISINVITESENT_ENDPOINT;
awsconfig.aws_cloud_logic_custom[1].endpoint = process.env.REACT_APP_GETTYPEFORM_ENDPOINT;
awsconfig.aws_appsync_graphqlEndpoint        = process.env.REACT_APP_GRAPHQLENDPOINT;


Amplify.configure(awsconfig);


const App = () => ( 
    <Router>
       <Suspense fallback={<div>Loading...</div>}>
       <Switch>
        <SignUp exact path="/SignUp" component={SignUp} />
        <Errorpage exact path="/Errorpage" component={Errorpage} />
        <SignUpLIL exact path="/SignUpLIL" component={SignUpLIL} />
        <SignIn path="/SignIn" component={SignIn} />
        <GoToHome path="/gth" component={GoToHome} />
        <ConfirmEmail path="/ConfirmEmail" component={ConfirmEmail} />
        <AutoConfirmEmail path="/AutoConfirmEmail" component={AutoConfirmEmail} />
        <ForgotPassword path="/ForgotPassword" component={ForgotPassword} />
        <ResetPassword path="/ResetPassword" component={ResetPassword} />
        <WelcomeScreen exact path="/Welcome" component={WelcomeScreen} />
        <PrivateRoute  exact  path="/" component={Home} /> 
        </Switch>
      </Suspense>
    </Router>


)

export default App