/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "",
    "aws_user_pools_web_client_id": "",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "inviteSent",
            "endpoint": "",
            "region": "us-east-1"
        },
        {
            "name": "getTFlink",
            "endpoint": "",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;