import { createMuiTheme, responsiveFontSizes  } from '@material-ui/core/styles';

const palette = {
  primary:      {main: "#fff"},
  secondary:    {main: "#6042be" },
  error:        {main: '#FF0000'},
  warning:       {main: '#E5EFF0'},
  info:        {main: '#E5EFF0'},
  background: {default: "#fff"},
  text: {primary: "#383838",
         secondary: '#9474ff',
        error:'#E5EFF0'}
};

const typography = {
 
    fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'Lucida', 'sans-serif'].join(','),
    h2: {
      fontSize: 30,
      fontWeight: 'normal',
    },

    h3: {
      fontSize: 25,
      fontWeight: 'normal',
    },
    
    h4: {
      fontSize: 29,
      fontWeight: 'normal',
    },
    
    h5: {
      fontSize: 18,
      fontWeight: 'normal',
    },
    h6: {
      fontSize: 16,
    },

    body1:{
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '19px'
    },

    body2:{
        fontSize: '11px',
        fontWeight: 'normal',
        lineHeight: '19px',
        letterSpacing: '0.15px'
    },
    body:{
      backgroundImage: "url('../background.png')",
    }
  };
  
const themeName = 'awsgilly';

let th = createMuiTheme({ palette,  typography, themeName });
th = responsiveFontSizes(th);

export default th;